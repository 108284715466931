.calendarHome {
  &__title_row {
    padding: 27px 20px 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p {
      color: $color-primary-dark;
      font-size: 24px;
      font-weight: 600;
    }
    &__search_row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
    &__search {
      max-width: 304px;
      width: 290px;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      min-height: 42px;
      input {
        width: 100%;
        padding: 13px 33px 13px 12px;
        border-radius: 8px;
        border: 1px solid $color-light-light-gray;
        background: $color-white;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-gray;
      }
      img {
        position: absolute;
        right: 12px;
        top: 13px;
        cursor: pointer;
      }
    }
    &__popup {
      position: absolute;
      top: 145px;
      right: 20px;
      width: 200px;
      border-radius: 8px;
      border: 1px solid $color-light-light-gray;
      background: $color-white;
      padding: 4px 12px;
      z-index: 100;
      &-list {
        display: inline-flex;
        flex-direction: column;
        gap: 6px;
        list-style-type: none;
        margin: 0;
        padding: 0;
        max-height: 250px;
        overflow: hidden;
        overflow-y: auto;
        &-name {
          font-size: 13px;
          font-weight: 400;
          color: $color-darkLiver;
          margin: 0;
          padding: 4px 0;
          cursor: pointer;
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 8px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-track {
          // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          // background-color: rgb(167, 165, 165);
        }
      }
    }
  }
  &__selection_row {
    width: 100%;
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .date_selection {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      &__arrow_button {
        cursor: pointer;
        transform: scale(1); /* Use transform instead of scale */
        transition: transform 1s; /* Apply transition to the transform property */
        background: none;
        border: none;
        display: flex;
        img {
          &:hover {
            transform: scale(1.25); /* Scale the image on hover */
            transition: transform 0.75s; /* Smooth transition on hover */
          }
        }
        span{
          font-size: 24px;
        }
      }
      .month-name {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        min-width: 120px;
        margin: 2px 0 0 0;
        color: $color-primary-dark;
        text-align: center;
      }
    }
    .filter-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      &__select-edit {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .react-dropdown-select {
          width: 261px;
          height: 42px;
          border-radius: 8px;
          padding: 12px 0 12px 12px;
          background: $color-white;
          color: $color-gray;
          border: 1px solid $color-light-light-gray;
          /* border-right: none; */
        }
        .react-dropdown-select-dropdown-handle {
          border: none;
          width: 50px;
          height: 40px;
          border-radius: 8px 0px 0px 8px;
          background-color: $color-light-gray;
          margin: 0 !important;
          svg {
            width: 16px;
            height: 37px;
          }
        }
        .positionHead {
          margin: 0 0 2px 0;
          color: $color-oldSilver;
        }
        .edit-board {
          border: none;
          width: 42px;
          height: 42px;
          border-radius: 0px 8px 8px 0px;
          background-color: $color-light-gray;
          border: 1px solid $color-light-light-gray;
          padding: 10px 12px 12px;
          line-height: 13px;
          &:disabled {
            opacity: 0.5;
          }
        }
        .dropdown {
          width: 100%;
          .dropdown-toggle {
            background: $color-white;
            border: 1px solid $color-light-light-gray;
            border-radius: 4px;
            color: $color-gray;
            height: 44px;
            padding: 8px 35px 8px 12px;
            width: 100%;
            text-align: left;
            position: relative;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &::after {
              display: none;
            }
            .arrow-icon {
              position: absolute;
              right: 12px;
              img {
                transform: rotate(180deg);
                width: 18px;
                height: 18px;
                vertical-align: -2px;
              }
            }
            &.show {
              .arrow-icon {
                img {
                  transform: rotate(0deg);
                }
              }
            }
          }
          .dropdown-menu.show {
            border: 1px solid rgb(204, 204, 204);
            width: 273px;
            padding: 0px;
            display: flex;
            flex-direction: column;
            background: rgb(255, 255, 255);
            border-radius: 2px;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
            max-height: 200px;
            overflow: auto;
            z-index: 9;
            &::-webkit-scrollbar {
              width: 6px;
              height: 8px;
              background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              border-radius: 10px;
              background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: rgb(167, 165, 165);
            }
            .dropdown-item {
              background: $color-white;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 12.563px;
              text-transform: capitalize;
              color: $color-gray;
              margin: 0;
              cursor: pointer;
              &:hover {
                background: $color-light-gray;
              }
              &.active {
                color: $color-gray;
                text-decoration: none;
                background-color: $color-light-gray;
              }
            }
          }
        }
      }
      &__select_tab_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        // background-color: $color-light-gray;
        background-color: $color-light-gray;
        padding: 4px 8px;
        border-radius: 8px;
        height: 45px;
        gap: 10px;
        .activeTab {
          padding: 6px 26px;
          background-color: $color-white;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          -webkit-box-shadow: 0px 1px 3px 0px rgba(27, 31, 53, 0.55);
          -moz-box-shadow: 0px 1px 3px 0px rgba(27, 31, 53, 0.55);
          box-shadow: 0px 1px 3px 0px rgba(27, 31, 53, 0.55);
          border: none;
          span {
            display: inline-block;
            margin: auto 0;
            color: $color-primary-dark;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .inActiveTab {
          padding: 3px 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background: transparent;
          border: none;
          span {
            display: inline-block;
            margin: auto 0;
            color: $color-primary-dark;
            font-size: 14px;
          }
        }
      }
    }
  }
  .calendar_container {
    width: 100%;
    overflow-x: auto;
  }
  &__calendar_box_day_show,
  &__calendar_box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 20px;
    flex-wrap: wrap;
    .loader {
      margin: auto;
      width: 200px;
      left: 0px;
      right: 0px;
      top: 0;
      bottom: 0;
      span {
        font-family: inherit;
        font-weight: 500;
        letter-spacing: 2px;
      }
    }
    .calendar_col {
      width: 14%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $color-light-light-gray;
      .activeHeader {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-primary-light-8;
        border: 1px solid $color-primary-dark-1;
        padding: 14px;
        border-radius: 4px;
        position: relative;
        .day_text {
          color: $color-darkLiver;
        }
      }
      .inActiveHeader {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;
        position: relative;
      }
      p {
        margin: auto 0;
      }
      .date_text {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: $color-darkLiver;
        position: relative;
        .event_badge {
          min-height: 17px;
          min-width: 17px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $color-primary;
          color: $color-white;
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          position: absolute;
          margin: 0 0 0 35px;
          top: -12px;
          right: -20px;
        }
      }
      .day_text {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: $color-oldSilver;
        margin: 5px 0 0 0;
      }

      .detail_column {
        margin: 3% 0 0 0;
        width: 100%;
        border: 1px solid $color-light-light-gray;
        border-bottom: none;
        border-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100vh - 350px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 4px;
          height: 8px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
          background-color: rgb(167, 165, 165);
        }

        &.page-loading {
          -webkit-animation: loading 2s ease-in-out infinite;
          animation: loading 2s ease-in-out infinite;
          background: linear-gradient(
            90deg,
            $color-light-ghost-white 25%,
            $color-light-light-gray-1 50%,
            $color-light-light-gray-4 75%
          );
          background-size: 200% 100%;
        }
        @keyframes loading {
          0% {
            background-position: 200% 0;
          }
          100% {
            background-position: -200% 0;
          }
        }
        .event_container {
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          background-color: $color-primary-light-9;
          width: 95%;
          margin: 10px 0 10px;
          padding: 18px 12px;
          border-left: 2px solid $color-primary;
          cursor: pointer;
          position: relative;
          .event_due_date_today {
            position: absolute;
            top: 0;
            left: -2px;
            border-radius: 8px 0 8px 0;
            color: $color-white;
            padding: 3px 10px 4px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            font-style: normal;
            /* Gradient background animation */
            background: $color-light-yellow-2;
            background-size: 400% 400%;
            span {
              display: inline-block;
              -webkit-animation: zoomEffect 1.5s ease-in-out infinite;
              -moz-animation: zoomEffect 1.5s ease-in-out infinite;
              animation: zoomEffect 1.5s ease-in-out infinite;
            }
          }
          /* Keyframes for zoom effect */
          @keyframes zoomEffect {
            0%,
            100% {
              transform: scale(1); /* Normal size */
            }
            50% {
              transform: scale(1.1); /* Zoom in */
            }
          }
          /* Keyframes for zoom effect */
          @-webkit-keyframes zoomEffect {
            0%,
            100% {
              transform: scale(1); /* Normal size */
            }
            50% {
              transform: scale(1.1); /* Zoom in */
            }
          }
          /* Keyframes for zoom effect */
          @-moz-keyframes zoomEffect {
            0%,
            100% {
              transform: scale(1); /* Normal size */
            }
            50% {
              transform: scale(1.1); /* Zoom in */
            }
          }
          .due_date_today,
          .event_due_date_today + .event_header {
            margin-top: 6px;
          }
          &:first-child {
            margin: 20px 0 10px;
          }
          .event_header {
            font-size: 17px;
            font-weight: 600;
            color: $color-primary-dark;
            line-height: 21.6px;
          }
          .event_code {
            margin-top: 5px;
            font-size: 12px;
            font-weight: 400;
            color: $color-darkLiver;
            line-height: 14.4px;
            span {
              color: $color-primary-dark;
              font-weight: 500;
            }
          }
          .event-label-flex {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            .event_type {
              background-color: $color-primary;
              width: fit-content;
              padding: 4px 15px;
              color: $color-white;
              font-size: 12px;
              border-radius: 20px;
              margin-top: 8px;
            }
          }
        }

        ::-webkit-scrollbar {
          width: 5px !important;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1 !important;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #888 !important;
          border-radius: 4px !important;
        }

        ::-webkit-scrollbar-thumb:hover {
          background-color: #555 !important;
        }
      }
    }
  }
  .border_bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      border-top: 1px solid $color-light-light-gray;
      width: 96%;
    }
  }
  .calendarHome__calendar_box__monthly {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    .loader {
      margin: auto;
      width: 200px;
      left: 0px;
      right: 0px;
      top: 20%;
      bottom: 0;
      span {
        font-family: inherit;
        font-weight: 500;
        letter-spacing: 2px;
      }
    }
  }
  .calendarHome__calendar_box_day_show {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 20px;
    flex-wrap: wrap;
    position: relative;
    // height: calc(100vh - 230px);
  }
  .calendarHome__calendar_box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 20px;
    flex-wrap: wrap;
    position: relative;
    height: calc(100vh - 230px);
  }
  &__calendar_box-weekly {
    display: flex;
    flex-direction: row;
    width: 98%;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 0 10px;
    height: calc(100vh - 355px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
      background-color: rgb(167, 165, 165);
    }
    .days_row {
      width: 100%;
      display: flex;
      flex-direction: row;
      &.page-loading {
        -webkit-animation: loading 2s ease-in-out infinite;
        animation: loading 2s ease-in-out infinite;
        background: linear-gradient(
          90deg,
          $color-light-ghost-white 25%,
          $color-light-light-gray-1 50%,
          $color-light-light-gray-4 75%
        );
        background-size: 200% 100%;
        width: 100%;
        height: calc(100vh - 800px);
      }
      @keyframes loading {
        0% {
          background-position: 200% 0;
        }
        100% {
          background-position: -200% 0;
        }
      }
    }
    .detail_column {
      width: 14%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid $color-light-light-gray;
      min-height: 5vh;
      &:last-child {
        border-right: none;
      }
      &.today {
        .event_container {
          background: $color-primary;
          .release_text_active,
          .date_text,
          .month_text,
          .release_text {
            color: $color-white;
          }
        }
      }
    }
    .event_container {
      width: 94%;
      margin-top: 5%;
      padding: 0px 10px;
      border: 1px solid $color-light-light-gray;
      border-radius: 5px;
      .release_text {
        color: $color-oldSilver;
        font-size: 16px;
        padding-top: 1%;
        margin-bottom: 5px;
      }
      .release_text_active {
        color: $color-darkLiver;
        font-size: 16px;
        padding-top: 1%;
        margin-bottom: 5px;
        font-weight: 600;
      }
      .date_row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        .date_text_col {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .date_text {
          color: $color-darkLiver;
          font-weight: 600;
          font-size: 30px;
          margin: auto;
        }
        .month_text {
          color: $color-oldSilver;
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 5px;
        }
        .data_section_list_data {
          padding: 12px 0 8px;
          font-size: 17px;
          text-transform: capitalize;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 10px;
          align-items: center;
          .data_section_list_image {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 0;
            width: 20px;
            height: 20px;
            overflow: hidden;
            border-radius: 50%;
            fill: $color-white;
            border: 1px solid $color-white;
            /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); */
            span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              font-weight: 500;
              font-size: 9px;
              line-height: 100%;
              text-align: center;
              border-radius: 50%;
              color: $color-white;
            }
            .avatars__others {
              background-color: $color-primary-light-6;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 100%;
              font-size: 13px;
              font-weight: 500;
              line-height: 100%;
              color: $color-primary-dark;
              border-radius: 50%;
            }
          }
          .data_section_list_image {
            margin-left: -14px;
          }
          .data_section_list_image:first-child {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

.popupModal__container.eventPopup {
  &.multiple-dates-show {
    .popupModal__root {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  .popupModal__root {
    width: 796px;
    min-height: 525px;
    padding: 24px;
    border-radius: 8px;

    .popupModal__heading .icon-close {
      right: 25px;
      top: 22px;
      color: $color-light-red;
    }
    .popupModal__body {
      .eventPopup-body {
        .head-label {
          display: block;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          text-align: left;
          text-decoration-skip-ink: none;
          margin: 0 0 4px;
        }
        .heading {
          font-size: 24px;
          font-weight: 600;
          line-height: 28.8px;
          text-align: left;
          text-decoration-skip-ink: none;
          margin: 0 0 20px;
          padding: 0;
        }
        .heading-flex {
          display: flex;
          flex-direction: row;
          gap: 10px;
          margin: 32px 0 20px;
          justify-content: space-between;
          align-items: end;
          .heading-flex-col {
            label {
              display: block;
              font-size: 14px;
              font-weight: 400;
              line-height: 16.8px;
              text-align: left;
              text-decoration-skip-ink: none;
              margin: 0 0 4px;
            }
            .heading {
              font-size: 24px;
              font-weight: 600;
              line-height: 28.8px;
              text-align: left;
              text-decoration-skip-ink: none;
              margin: 0;
              padding: 0;
            }
            .view-ticket-btn {
              border: 1px solid $color-oldSilver;
              background: none;
              font-size: 13px;
              font-weight: 400;
              line-height: 15.73px;
              text-align: center;
              text-decoration-skip-ink: none;
              color: $color-darkLiver;
              padding: 6px 16px;
              border-radius: 24px;
              &:hover {
                transition: background 1s;
                color: $color-white;
                background: $color-primary-dark;
              }
            }
          }
        }
        .suggested-date-flex {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          padding: 8px;
          border-radius: 4px;
          height: 48px;
          background: $color-primary-light-6;
          margin: 12px 0 20px;
          .suggested-date {
            position: relative;
            display: inline-flex;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            color: $color-black;
            text-decoration-skip-ink: none;
            align-items: center;
            justify-content: flex-start;
            gap: 15px;
            .showDates {
              position: relative;
              display: block;
              width: 18px;
              height: 100%;
              background: none;
              border: none;
              padding: 0;
            }
            .multiple-dates {
              width: 255px;
              min-height: 56px;
              padding: 8px 12px;
              position: absolute;
              right: -260px;
              top: 8px;
              border: 1px solid $color-light-gray-2;
              background: $color-white;
              border-radius: 6px;
              display: flex;
              flex-direction: row;
              gap: 10px;
              box-shadow: -1px 8px 20px 3px $color-light-light-gray;
              z-index: 1000;
              align-items: baseline;
              .multiple-dates-info {
                display: inline-flex;
                flex-direction: row;
                padding: 0;
                height: 19px;
                gap: 8px;
                width: 45px;
                img {
                  width: 13px;
                  filter: grayscale(100%) brightness(0%);
                }
              }
              .multiple-dates-flex {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 13px;
                width: 100%;
                max-height: 100px;
                overflow: auto;
                .date-entry {
                  color: $color-oldSilver;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 14.4px;
                  text-align: left;
                  text-decoration-skip-ink: none;
                  display: inline-flex;
                  .light-color {
                    color: $color-silver;
                    font-weight: 400;
                    max-width: 98px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: inline-block;
                    a {
                      color: inherit;
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
        }
        .messageContent {
          display: flex;
          flex-direction: row;
          gap: 10px;
          flex-wrap: wrap;
          align-items: center;
          margin: 8px 0 12px;
          width: 100%;
          .label-content {
            border-radius: 22px;
            padding: 6px 12px;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.4px;
            text-align: left;
            text-decoration-skip-ink: none;
            color: $color-white;
          }
        }
        .notes-body-content {
          margin: 15px 0 10px;
          overflow-y: auto;
          min-height: 100px;
          padding: 0 10px 0 0;
          &::-webkit-scrollbar {
            width: 6px;
            height: 8px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: rgb(167, 165, 165);
          }
          .error-msg {
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            color: $color-light-red;
          }
          .notes-edit {
            position: relative;
            .quill.false {
              pointer-events: none;
              user-select: none;
            }
            .edit-comment-div {
              width: 26px;
              height: 26px;
              position: absolute;
              right: 15px;
              bottom: 15px;
              padding: 2px 4px;
              border-radius: 4px;
              border: none;
              background: $color-white;
              .edit-comment {
                display: block;
                width: 100%;
                height: 100%;
                cursor: pointer;
                &:hover {
                  filter: brightness(0) invert(1);
                }
              }
              &:hover {
                transition: background 1s;
                background: $color-primary-dark;
                .edit-comment {
                  filter: brightness(0) invert(1);
                }
              }
            }
            .comments_actionBtn {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-end;
              row-gap: 0px;
              margin: 0;
              position: absolute;
              top: 20px;
              right: 16px;
              height: 93%;
              padding: 16px 0;
              .submit-btn {
                font-size: 12px;
                font-weight: 400;
                border-radius: 8px;
                line-height: 18px;
                background: $color-primary-dark;
                color: $color-white;
                padding: 4px 8px;
                border: none;
                cursor: pointer;
                position: relative;
                &:disabled {
                  opacity: 0.5;
                }
                &.loading {
                  background: $color-primary-dark;
                  padding-right: 35px;
                }
                &.loading::before {
                  content: "";
                  position: absolute;
                  border-radius: 100%;
                  right: 6px;
                  top: 50%;
                  width: 0px;
                  height: 0px;
                  margin-top: -2px;
                  border: 2px solid rgba(255, 255, 255, 0.5);
                  border-left-color: #fff;
                  border-top-color: #fff;
                  animation: spin 0.6s infinite linear,
                    grow 0.3s forwards ease-out;
                }
              }
              .icon-close {
                cursor: pointer;
                &:before {
                  font-size: 14px;
                }
              }
            }
          }
          .history-heading {
            font-size: 12px;
            font-weight: 400;
            line-height: 14.4px;
            letter-spacing: 0.005em;
            text-align: left;
            text-decoration-skip-ink: none;
            margin: 10px 0;
          }
          .no-notes-found {
            font-size: 14px;
            font-weight: 400;
            line-height: 14.4px;
            letter-spacing: 0.005em;
            text-align: left;
            text-decoration-skip-ink: none;
            margin: 0px 0 10px;
            color: $color-light-red;
            text-align: center;
          }
          .loading-skeleton {
            -webkit-animation: loading 2s ease-in-out infinite;
            animation: loading 2s ease-in-out infinite;
            background: linear-gradient(
              90deg,
              $color-light-light-gray 25%,
              $color-light-light-gray-1 50%,
              $color-light-light-gray-4 75%
            );
            background-size: 200% 100%;
            border-radius: 8px;
            height: 100%;
            min-height: 100px;
            width: 100%;
          }
          @keyframes loading {
            0% {
              background-position: 200% 0;
            }
            100% {
              background-position: -200% 0;
            }
          }
          .ticket_info_container_activity {
            &_contents {
              display: flex;
              flex-direction: column;
              gap: 0px;
              .commentInfo {
                position: relative;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                row-gap: 0px;
                padding: 8px 12px 8px 0px;
                /*border: 1px solid $color-light-light-gray;*/
                border-radius: 12px;
                &::before {
                  content: " ";
                  position: absolute;
                  left: 12px;
                  top: 0;
                  width: 2px;
                  background: $color-primary-light-7;
                  height: 100%;
                }
                &.hide-top-line::before {
                  top: 14px;
                }
                &.hide-bottom-line::before {
                  height: 95%;
                }
                &.no-line::before {
                  content: none;
                }

                &-d-flex {
                  position: relative;
                  gap: 7px;
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  padding: 0 0 0 25px;
                  .timeLineCircle {
                    display: block;
                    position: absolute;
                    left: 9px;
                    top: 5px;
                    background: $color-white;
                    width: 8px;
                    height: 8px;
                    &::before {
                      content: " ";
                      position: absolute;
                      width: 8px;
                      height: 8px;
                      background-color: $color-primary-light-7;
                      border-radius: 50px;
                      border: none;
                      outline: unset;
                      left: 0px;
                      top: 0px;
                    }
                  }
                }
                &-user {
                  display: inline-flex;
                  flex-direction: row;
                  column-gap: 7px;
                  align-items: center;
                  flex-wrap: wrap;
                  width: 20px;
                  &-icon {
                    width: 20px;
                    height: 20px;
                    margin: 0;
                    display: inline-flex;
                    align-items: center;
                    border-radius: 50%;
                    fill: $color-white;
                    border: 1px solid $color-primary;
                    vertical-align: middle;
                    .user-icon {
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      width: 20px;
                      height: 20px;
                      font-weight: 500;
                      font-size: 8px;
                      line-height: 100%;
                      text-align: center;
                      border-radius: 50%;
                      color: $color-white;
                    }
                    img {
                      display: block;
                      margin: 0;
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      border: 1px solid $color-primary;
                    }
                  }
                  .name {
                    color: $color-gray;
                  }
                }
                &-details-with-content {
                  display: flex;
                  flex-direction: column;
                  gap: 3px;
                  justify-content: baseline;
                  align-items: flex-start;
                  width: calc(100% - 30px);
                  .commentInfo-details {
                    display: inline-flex;
                    gap: 6px;
                    margin: 0px;
                    flex-direction: column;
                    position: relative;
                    width: 100%;
                    border-radius: 0px;
                    background: $color-white;
                    &-user {
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 14.5px;
                      color: $color-gray;
                      margin: 0;
                      display: inline-flex;
                      align-items: center;
                      justify-content: space-between;
                      gap: 6px;
                      width: 100%;
                      span {
                        font-size: 12px;
                        font-weight: 400;
                        color: $color-light-gray-7;
                      }
                    }
                    &-comments-box {
                      background: $color-white;
                      padding: 0px;
                      border-radius: 0px;
                      margin: 0;
                    }
                    &-comments-content {
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 16px;
                      color: $color-dark-gray-4;
                      margin: 0;
                      p,
                      ul {
                        margin: 0;
                      }
                      img {
                        max-width: 100%;
                      }
                      ul {
                        list-style-type: disc;
                      }

                      ul[data-checked="true"] {
                        list-style-type: none;
                        padding: 0;
                      }

                      ul[data-checked="true"] li:before {
                        content: "\2611";
                        vertical-align: middle;
                        padding: 0 10px;
                      }
                    }
                    &-attachments {
                      display: flex;
                      align-items: center;
                      gap: 18px;
                      flex-wrap: wrap;
                      padding: 0 0 10px 10px;
                      &-info {
                        display: inline-flex;
                        gap: 8px;
                        font-size: 15px;
                        font-weight: 500;
                        align-items: center;
                        color: $color-darkLiver;
                        border: 1px solid $color-darkLiver;
                        border-radius: 8px;
                        background: $color-light-gray;
                        padding: 2px 6px;
                        cursor: pointer;
                        span:first-child {
                          font-size: 18px;
                        }
                      }
                    }
                    .showMoreButton.right {
                      margin-top: 6px;
                    }
                  }
                }
                .commentInfo-edit {
                  display: block;
                  position: absolute;
                  top: 11px;
                  right: 20px;
                }
                &-btn {
                  float: right;
                  background-color: unset;
                  border: unset;
                  img {
                    width: 20px;
                  }
                }
              }
              .historyInfo {
                // display: flex;
                // gap: 6px;
                padding: 8px 0;
                &-user {
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 500;
                  color: $color-primary-dark;
                }
                &-data {
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 400;
                  color: $color-light-gray-7;
                  &-assign,
                  &-ticketmovement {
                    font-size: 15px;
                    font-weight: 500;
                    color: $color-primary-dark;
                  }
                  &-updatedOn {
                    font-size: 12px;
                    display: block;
                    color: $color-light-gray-7;
                  }
                }
              }
              .no-activity-comments {
                margin: 10px auto;
                text-align: center;
                & .type-name {
                  text-transform: capitalize;
                  font-weight: 500;
                }
              }

              .editComment {
                position: relative;
                &__popup {
                  position: absolute;
                  top: 30px;
                  right: 10px;
                  width: 153px;
                  border-radius: 12px;
                  background: $color-white;
                  box-shadow: 0px 2px 4px 0px rgba(27, 31, 53, 0.12);
                  z-index: 150;
                  & button {
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    border: none;
                    padding: 8px 12px 8px;
                    width: 100%;
                    background-color: $color-white;
                    border-bottom: 1px solid $color-light-light-gray;
                    &:last-child {
                      border-bottom: none;
                      padding-top: 8px;
                    }
                  }
                  &--gotoEdit {
                    text-align: left;
                  }
                  &--archive {
                    text-align: left;
                    color: $color-light-red;
                  }
                }
                &-container {
                  &_input {
                    font-size: 15px;
                    border-radius: 6px;
                    border: 1px solid $color-light-light-gray;
                    width: 100%;
                    height: 42px;
                    resize: none;
                    padding: 8px 12px;
                    color: $color-oldSilver;
                    // overflow: hidden;
                    height: 140px;
                    &:focus-visible {
                      outline: unset;
                    }
                    &::-webkit-scrollbar {
                      width: 8px;
                      height: 4px;
                      background-color: $color-light-light-gray;
                      border-radius: 10px;
                    }
                    &::-webkit-scrollbar-track {
                      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                      border-radius: 10px;
                      background-color: $color-light-light-gray;
                    }
                    &::-webkit-scrollbar-thumb {
                      border-radius: 10px;
                      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                      background-color: $color-oldSilver;
                    }
                  }
                  ::placeholder {
                    font-style: italic;
                  }
                  &_actionBtn {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    column-gap: 30px;
                    margin-top: 15px;
                    .submit-btn {
                      font-size: 15px;
                      font-weight: 400;
                      border-radius: 24px;
                      background: $color-primary-dark;
                      color: $color-white;
                      padding: 8px 16px;
                      border: none;
                      cursor: pointer;
                    }
                    .cancel-btn {
                      border-radius: 24px;
                      border: 1px solid $color-light-light-gray;
                      background: $color-white;
                      font-size: 15px;
                      padding: 8px 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
            &_more {
              display: flex;
              flex-direction: column;
              gap: 8px;
              padding-top: 10px;
              button {
                border: unset;
                color: $color-white;
                font-size: 14px;
                font-weight: 500;
                background: $color-primary-dark;
                padding: 5px;
                border-radius: 4px;
                display: inline-flex;
                align-items: center;
                gap: 10px;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 1124px) and (min-width: 760px) {
//   .calendarHome__calendar_box {
//     width: 293vh !important;
//   }
// }
