
  .popupModal__container.module-iteration-detailedview .popupModal__root {
    min-width: 735px;
    padding: 20px;
    .popupModal__heading {
      margin-bottom: 15px;
      h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        text-decoration-skip-ink: none;
        color: $color-primary-dark;
        margin: 0;
        width: 100%;
      }
      .icon-close {
        color: #EB5757
      }
    }
    .popupModal__body {
      .module-iteration-detailedview-container{
        display: flex;
        gap: 15px;
        flex-direction: column;
        .requirement-container {
          font-weight: 400;
          font-size: 14px;
          color: $color-darkLiver;
          .title {            
            margin: 8px 0px;
          }
          .requirement_render_box {
            width: 100%;
            min-height: 18vh;
            border: 1px solid $color-light-light-gray-1;
            border-radius: 5px;
            padding: 8px 10px;
          }
        }
        .links_row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
          .link_column {
            display: inline-flex;
            gap: 12px;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: $color-primary-dark;
            .icon-redirect, .icon-copy{
              background: unset;
              border: unset;
              padding: 0;
            }
          }
        }
      
        .attachment-container {
          font-size: 14px;
          font-weight: 400;
          color: $color-darkLiver;
          .box-container {
            padding: 12px;
            display: flex;
            border: 1px solid $color-light-light-gray-1;
          }
          .files-container {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            box-sizing: border-box;
            flex-wrap: wrap;
            border: 1px solid $color-light-light-gray-1;
            border-radius: 5px;
            padding: 10px 10px;
            gap: 16px;
            .file-info {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;
              
            }
          }
        }      
      }
    }
  }  