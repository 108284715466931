.ticket_info {
  padding: 0;
  display: block;
  .flex-row {
    padding: 15px 25px;
    position: fixed;
    height: 55px;
    width: 100%;
    z-index: 100;
    background: #fff;
    border-bottom: 1px solid $color-light-light-gray;
    /*box-shadow: -2px 3px 8px 1px #e0e0e0;*/
  }
  &_container {
    max-width: calc(100% - 355px);
    display: inline-flex;
    flex-direction: column;
    padding: 27px 25px;
    width: 100%;
    margin: 55px 0 0;
    // border-top: 1px solid var(--color-light-light-gray);
    @media (max-width: 1100px) {
      max-width: 100%;
    }

    &_breadcrumb {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      align-items: center;
      &_actions {
        position: relative;
        > * {
          font-size: 15px;
          font-weight: 400;
          border-radius: 4px;
          background: $color-primary-dark;
          color: $color-white;
          padding: 8px 16px;
          border: none;
          float: right;
          margin: 28px 0 10px;
        }
        .addUpdateRequirement {
          box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
          &:disabled {
            opacity: 0.5;
          }
        }
        .add-requirement-tip {
          top: -60px;
          right: 80px;
          .showPopup {
            .tip-entry {
              color: $color-light-red;
            }
          }
        }
      }
      &_open {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $color-darkLiver;
        .display-icon {
          display: inline-flex;
          align-items: center;
          border-radius: 50%;
          // width: 18px;
          // height: 18px;
          fill: var(--color-white);
          border: 1px solid $color-primary;
          vertical-align: middle;
          .user-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            text-align: center;
            border-radius: 50%;
            color: $color-white;
          }
        }
        .name {
          color: $color-gray;
        }
      }
    }
    &_description {
      display: flex;
      justify-content: space-between;
      padding: 0;
      align-items: center;
      .agency_edit_buttom {
        width: 62px;
        height: 34px;
        padding: 8px 10px 8px 10px;
        border-radius: 4px;
        border: 1px solid $color-primary-dark;
        color: $color-darkLiver;
        background: none;
        font-size: 15px;
        font-weight: 400;
        line-height: 18.15px;
        text-align: center;
      }
      &_title {
        font-size: 24px;
        font-weight: 500;
        margin: 0;
        color: $color-black;
      }
      .flex-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        column-gap: 20px;
        .edit-status {
          background-color: $color-white;
          border: none;
          width: 24px;
          padding: 0;
        }
        .popup-content {
          min-width: 20vh;
        }
      }
      &_actions {
        font-size: 15px;
        font-weight: 400;
        text-decoration: none;
        color: $color-white;
        border-radius: 4px;
        border: 1px solid $color-primary-dark;
        font-style: normal;
        line-height: normal;
        text-transform: capitalize;
        padding: 5px 17px;
        background-color: $color-primary-dark;
        box-shadow: 1px 2px 7px 0px $color-primary-dark-160;
        &:disabled {
          opacity: 0.5;
        }
      }
      &_periodicEdit {
        border: none;
        background: none;
        &:disabled {
          opacity: 0.5;
        }
      }
      .ticket-status {
        font-size: 15px;
        font-weight: 600;
        &.open {
          color: $color-darkLiver;
        }
        &.in-progress {
          color: $color-orange-lighter;
        }
        &.pause {
          color: $color-orange;
        }
        &.completed {
          color: $color-light-green;
        }
      }
      .ticket-status-popup {
        width: unset;
      }
      .ticket-list-label {
        width: fit-content;
        background-color: $color-light-gray-2;
        padding: 4px 12px 8px 8px;
        border-radius: 4px;
        height: 34px;
        min-width: 80px;
        .update-status {
          font-size: 13px;
          color: $color-primary-dark;
          font-weight: 600;
          line-height: 18px;
          .icon-chevron-thin-down:before {
            font-size: 15px;
          }
        }
        .popup-content {
          width: fit-content;
          z-index: 100;
          .tip-entry.active {
            pointer-events: none;
            cursor: not-allowed;
            user-select: none;
          }
        }
        &.disabled {
          user-select: none;
          pointer-events: none;
        }
      }
      .editName {
        width: 100%;
        .ticket-name-changed {
          display: block;
          border-radius: 6px;
          width: 100%;
          margin: 0 0 15px;
          padding: 12px;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border: 1px solid $color-light-light-gray;
          color: $color-gray;
        }
        .error-msg {
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          color: $color-light-red;
          height: 12px;
          display: block;
          margin: 2px 0 8px 0;
        }
        .ticket-description {
          display: block;
          width: 100%;
          min-height: 210px;
          padding: 12px;
          border: 1px solid $color-light-light-gray;
          border-radius: 6px;
          font-size: 13px;
          font-style: italic;
          font-weight: 400;
          line-height: normal;
          color: $color-oldSilver;
          resize: none;
          margin: 0 0 8px;
        }
        .quill {
          .ql-container {
            .ql-editor {
              min-height: 210px;
            }
          }
          margin: 0 0 8px;
        }
        .flex-btn {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          column-gap: 20px;
          margin: 10px 0 0 0;
          .save-btn {
            font-size: 15px;
            font-weight: 400;
            border-radius: 24px;
            background: $color-primary-dark;
            color: $color-white;
            padding: 8px 16px;
            border: none;
            &:disabled {
              user-select: none;
              pointer-events: none;
              opacity: 0.5;
            }
          }
          .cancel-btn {
            border-radius: 24px;
            border: 1px solid $color-light-light-gray;
            background: $color-white;
            font-size: 15px;
            padding: 8px 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
    &_tools {
      font-size: 15px;
      color: $color-gray;
      font-weight: 400;
      &_info {
        display: flex;
        justify-content: space-between;
        padding: 11px 0;
        input {
          margin-right: 6px;
        }
      }
    }
    &_lastUpdate {
      font-size: 15px;
      font-weight: 400;
      color: $color-darkLiver;
      padding-top: 12px;
    }
    &_activity {
      padding-top: 8px;
      margin: 0 0 15px;
      &_comments {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
       
        .display-icon {
          display: inline-flex;
          align-items: center;
          border-radius: 50%;
          fill: var(--color-white);
          border: 1px solid $color-primary;
          vertical-align: middle;
          .user-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            text-align: center;
            border-radius: 50%;
            color: $color-white;
          }
        }
        .name {
          color: $color-gray;
        }
        .defaultExist {
          width: 100%;
          position: relative;
          // #toolbar-parentTicket {
          //   display: none;
          // }
          #toolbar-parentTicket-show-tools {
            display: block;
          }
        }

        &_header {
          font-size: 17px;
          font-weight: 500;
          letter-spacing: 0.1px;
        }
        &_input {
          font-size: 15px;
          border-radius: 6px;
          border: 1px solid $color-light-light-gray;
          width: 100%;
          height: 42px;
          resize: none;
          padding: 8px 12px;
          color: $color-oldSilver;
          // overflow: hidden;
          &:focus-visible {
            outline: unset;
          }
          &::-webkit-scrollbar {
            width: 8px;
            height: 4px;
            background-color: $color-light-light-gray;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
            border-radius: 10px;
            background-color: $color-light-light-gray;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
            background-color: $color-oldSilver;
          }
        }
        ::placeholder {
          font-style: italic;
        }
        &_input.focused {
          height: 140px;
        }
        &_actionBtn {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          row-gap: 0px;
          margin: 0;
          position: absolute;
          right: 16px;
          height: 100%;
          padding: 16px 0;
          .submit-btn {
            font-size: 15px;
            font-weight: 400;
            border-radius: 8px;
            line-height: 18px;
            background: $color-primary-dark;
            color: $color-white;
            padding: 6px 10px;
            border: none;
            cursor: pointer;
          }
          .icon-close {
            cursor: pointer;
          }
        }
      }
      .error-msg {
        color: $color-light-red;
        font-size: 11px;
        margin: 0px 0px 10px;
        padding: 4px 0 0 50px;
        text-align: left;
        width: 100%;
      }
      .attachmentExist {
        border: 1px solid $color-primary-light-1;
        border-radius: 8px;
        width: 100%;
        .comment-attachment {
          border: none;
        }
      }
      .attachmentExist,
      .editComment-container {
        position: relative;
        padding: 0;
        .ql-container.ql-snow .ql-editor {
          min-height: 140px;
          max-height: 500px;
        }
      }
      &_filter {
        display: flex;
        gap: 9px;
        font-size: 13px;
        color: $color-darkLiver;
        font-weight: 400;
        align-items: center;
        padding: 24px 0;
        justify-content: space-between;
        label {
          font-size: 15px;
          color: $color-darkLiver;
          font-weight: 400;
        }
        .d-flex {
          display: inline-flex;
          gap: 9px;
          button {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            border: none;
            padding: 4px 6px;
            cursor: pointer;
            color: $color-light-gray-8;
            border: none;
            background: none;
          }
          .active {
            color: $color-primary-dark;
          }
          &.historicalView {
            align-items: center;
            .historicalView-head {
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              padding: 0;
              color: $color-primary-dark;
              margin: 0;
            }
            button {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              padding: 0;
              cursor: pointer;
              color: $color-light-gray-8;
              border: none;
              background: none;
              img {
                width: 28px;
                height: 100%;
              }
            }
            .active {
              color: $color-primary-dark;
            }
          }
        }
      }
      &_contents {
        display: flex;
        flex-direction: column;
        gap: 0px;
        & .activity {
          position: relative;
          padding: 10px 0 0 45px;
          margin: 0;
          .historyInfo {
            position: relative;
            padding: 0 0 16px 0;
            .vertical-line {
              display: block;
              position: absolute;
              left: -22px;
              bottom: 0px;
              height: 50%;
              width: 4px;
              background: $color-white;
            }
            .timeLineCircle {
              display: block;
              position: absolute;
              left: -42px;
              top: 0px;
              background: $color-white;
              width: 42px;
              height: 42px;
              &::before {
                content: " ";
                position: absolute;
                width: 22px;
                height: 22px;
                background-color: $color-primary;
                border-radius: 50px;
                border: 5px solid $color-white;
                outline: 1px solid $color-primary-dark;
                left: 10px;
                top: 10px;
              }
            }
            &:last-child {
              &::before {
                content: " ";
                position: absolute;
                left: -39px;
                bottom: 0;
                width: 25px;
                background: $color-white;
              }
            }
          }
          &::before {
            content: " ";
            position: absolute;
            left: 22px;
            top: 0;
            width: 4px;
            background: $color-primary;
            height: 100%;
          }
        }
        .commentInfo {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          row-gap: 0px;
          padding: 8px 12px 8px 0px;
          /*border: 1px solid $color-light-light-gray;*/
          border-radius: 12px;
          &-d-flex {
            // align-items: center;
            gap: 7px;
            width: 100%;
          }
          &-user {
            display: inline-flex;
            flex-direction: row;
            column-gap: 10px;
            align-items: center;
            flex-wrap: wrap;
            &-icon {
              width: 32px;
              height: 32px;
              margin: 0;
              display: inline-flex;
              align-items: center;
              border-radius: 50%;
              fill: var(--color-white);
              border: 1px solid $color-primary;
              vertical-align: middle;
              .user-icon {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                font-weight: 500;
                font-size: 10px;
                line-height: 100%;
                text-align: center;
                border-radius: 50%;
                color: $color-white;
              }
              img {
                display: block;
                margin: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 1px solid $color-primary;
              }
            }
            .name {
              color: $color-gray;
            }
          }
          &-details {
            display: inline-flex;
            gap: 6px;
            margin: 0 0 0 40px;
            flex-direction: column;
            position: relative;
            width: calc(100% - 40px);
            border-radius: 8px;
            background: $color-light-light-gray-4;
            &-user {
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              color: $color-primary-dark;
              margin: 0;
              display: inline-flex;
              align-items: center;
              gap: 6px;
              span {
                font-size: 11px;
                font-weight: 400;
                color: $color-light-gray-7;
              }
            }
            &-comments-box {
              background: $color-light-light-gray-4;
              padding: 16px 70px 16px 16px;
              border-radius: 8px;
              margin: 0;
            }
            &-comments-content {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              color: $color-dark-gray-4;
              margin: 0;
              p,
              ul {
                margin: 0;
              }
              img {
                max-width: 100%;
              }
              ul {
                list-style-type: disc;
              }

              ul[data-checked="true"] {
                list-style-type: none;
                padding: 0;
              }

              ul[data-checked="true"] li:before {
                content: "\2611";
                vertical-align: middle;
                padding: 0 10px;
              }
            }
            &-attachments {
              display: flex;
              align-items: center;
              gap: 18px;
              flex-wrap: wrap;
              padding: 0 0 10px 10px;
              &-info {
                display: inline-flex;
                gap: 8px;
                font-size: 15px;
                font-weight: 500;
                align-items: center;
                color: $color-darkLiver;
                border: 1px solid $color-darkLiver;
                border-radius: 8px;
                background: $color-light-gray;
                padding: 2px 6px;
                cursor: pointer;
                span:first-child {
                  font-size: 18px;
                }
              }
            }
            .showMoreButton.right {
              margin-top: 6px;
            }
          }

          .commentInfo-edit {
            display: block;
            position: absolute;
            top: 11px;
            right: 20px;
          }
          &-btn {
            float: right;
            background-color: unset;
            border: unset;
            img {
              width: 20px;
            }
          }
        }
        .historyInfo {
          // display: flex;
          // gap: 6px;
          padding: 8px 0;
          &-user {
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            color: $color-primary-dark;
          }
          &-data {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            color: $color-light-gray-7;
            &-assign,
            &-ticketmovement {
              font-size: 15px;
              font-weight: 500;
              color: $color-primary-dark;
            }
            &-updatedOn {
              font-size: 12px;
              display: block;
              color: $color-light-gray-7;
            }
          }
        }
        .no-activity-comments {
          margin: 10px auto;
          text-align: center;
          & .type-name {
            text-transform: capitalize;
            font-weight: 500;
          }
        }

        .editComment {
          position: relative;
          &__popup {
            position: absolute;
            top: 30px;
            right: 10px;
            width: 153px;
            border-radius: 12px;
            background: $color-white;
            box-shadow: 0px 2px 4px 0px rgba(27, 31, 53, 0.12);
            z-index: 150;
            & button {
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              border: none;
              padding: 8px 12px 8px;
              width: 100%;
              background-color: $color-white;
              border-bottom: 1px solid $color-light-light-gray;
              &:last-child {
                border-bottom: none;
                padding-top: 8px;
              }
            }
            &--gotoEdit {
              text-align: left;
            }
            &--archive {
              text-align: left;
              color: $color-light-red;
            }
          }
          &-container {
            &_input {
              font-size: 15px;
              border-radius: 6px;
              border: 1px solid $color-light-light-gray;
              width: 100%;
              height: 42px;
              resize: none;
              padding: 8px 12px;
              color: $color-oldSilver;
              // overflow: hidden;
              height: 140px;
              &:focus-visible {
                outline: unset;
              }
              &::-webkit-scrollbar {
                width: 8px;
                height: 4px;
                background-color: $color-light-light-gray;
                border-radius: 10px;
              }
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
                border-radius: 10px;
                background-color: $color-light-light-gray;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
                background-color: $color-oldSilver;
              }
            }
            ::placeholder {
              font-style: italic;
            }
            &_actionBtn {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              column-gap: 30px;
              margin-top: 15px;
              .submit-btn {
                font-size: 15px;
                font-weight: 400;
                border-radius: 24px;
                background: $color-primary-dark;
                color: $color-white;
                padding: 8px 16px;
                border: none;
                cursor: pointer;
              }
              .cancel-btn {
                border-radius: 24px;
                border: 1px solid $color-light-light-gray;
                background: $color-white;
                font-size: 15px;
                padding: 8px 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
              }
            }
          }
        }
      }
      &_more {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 10px;
        button {
          border: unset;
          color: $color-white;
          font-size: 14px;
          font-weight: 500;
          background: $color-primary-dark;
          padding: 5px;
          border-radius: 4px;
          display: inline-flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
        }
      }
      .popupModal__container.archive-comment {
        .popupModal__root {
          padding: 16px;
          width: 396px;
          border-radius: 6px;
          min-height: 103px;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 8px;
            height: 4px;
            background-color: $color-light-light-gray;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
            border-radius: 10px;
            background-color: $color-light-light-gray;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
            background-color: $color-oldSilver;
          }
          .popupModal__heading {
            .icon-close {
              top: 20px;
              right: 20px;
              font-size: 13px;
            }
            h2 {
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              color: $color-primary-dark;
              text-align: justify;
              margin: 0px 0px 25px;
            }
          }
          .popupModal__body {
            min-height: auto;
            .flex-btn {
              float: right;
              display: flex;
              flex-direction: row;
              column-gap: 16px;
              margin: 20px 0 0;

              .cancel-btn {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 24px;
                color: $color-oldSilver;
                border: 1px solid $color-oldSilver;
                background: $color-white;
                height: 28px;
                width: 71px;
              }

              .board-delete {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 24px;
                color: $color-white;
                background: $color-light-red;
                height: 28px;
                min-width: 71px;
                position: relative;
                padding: 0px 16px;
                border: none;
              }

              .board-delete.loading::after {
                content: "";
                position: absolute;
                border-radius: 100%;
                right: 6px;
                top: 50%;
                width: 0px;
                height: 0px;
                margin-top: -2px;
                border: 2px solid rgba(255, 255, 255, 0.5);
                border-left-color: #fff;
                border-top-color: #fff;
                animation: spin 0.6s infinite linear,
                  grow 0.3s forwards ease-out;
              }

              @keyframes spin {
                to {
                  transform: rotate(359deg);
                }
              }

              @keyframes grow {
                to {
                  width: 14px;
                  height: 14px;
                  margin-top: -8px;
                  right: 13px;
                }
              }
            }
          }
        }
      }
      .finanancial-activity {
        width: 100%;
        background: none;
        border: none;
        border-radius: 0;
        .collapseCard {
          width: 100%;
          background: none;
          border: none;
          border-radius: 0;
          .collapseCard__heading {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            background: none;
            color: $color-black;
            border-bottom: 1px solid $color-platinum;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &.active {
              background: none;
              color: $color-black;
            }
          }
          .collapseCard__body.show {
            .ticket_info_container_activity_contents {
              margin: 0 0 20px;
            }
          }
        }
      }
    }
    &_modules-iterations-overview {
      margin: 10px 0;
    }
    &_updatedDetails {
      margin: 10px 0 20px;
      .collapseCard {
        border-radius: 4px;
        margin: 24px 0px;
        &.fileUploads,
        &.dataInputs {
          padding: 12px 0px 0px;
        }
        &.customer-timeline {
          border-top: none;
        }
        &:last-child {
          border-bottom: 1px solid $color-platinum;
        }
        &__heading {
          padding: 0px 20px 10px;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          color: $color-gray;
          .icon-chevron-thin-down {
            display: inline-block;
            position: absolute;
            right: 12px;
            &::before {
              font-size: 12px;
              vertical-align: -2px;
              font-weight: 700;
            }
          }
          &.active {
            background-color: transparent;
            // color: initial;
            .icon-chevron-thin-down {
              transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              &::before {
                vertical-align: 4px;
              }
            }
          }
        }
        .collapseCard__body {
          background: $color-light-gray;
          display: none;
          padding: 0;
          &.show {
            display: block;
            background: $color-light-gray;
            border-top: 1px solid $color-platinum;
            border-radius: 0px 0px 5px 5px;

            margin: 0 0;
            position: relative;
            font-size: 15px;
            color: $color-darkLiver;
          }
          .list-info {
            .popup-button {
              padding: 14px 24px 14px;
              border-bottom: 1px solid $color-light-light-gray;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              p {
                margin: 0;
              }
              .dependency_status {
                width: 13px;
              }
              .dependency-state {
                display: inline-flex;
                gap: 8px;
                margin: 4px 0;
                span {
                  background-color: $color-light-red;
                  color: $color-white;
                  padding: 4px 10px;
                  font-size: 13px;
                  font-weight: 400;
                  border-radius: 14px;
                }
              }
            }

            .ordered-tools {
              padding: 17px 12px;
              @media (max-width: 1200px) {
                flex-wrap: wrap;
              }
            }
          }
          .file-info {
            &-container {
              display: flex;
              padding: 12px 20px 12px;
              border-bottom: 1px solid $color-light-light-gray;
              align-items: center;
              font-size: 17px;
              font-weight: 500;
              justify-content: space-between;
              .name {
                display: inline-flex;
                align-items: center;
                gap: 12px;
                span:first-child {
                  font-size: 32px;
                }
              }
              .flex-data {
                display: inline-flex;
                flex-direction: row;
                column-gap: 20px;
                justify-content: flex-start;
                align-items: center;
                .icon-close {
                  color: $color-light-red;
                  cursor: pointer;
                }
                .icon-download_file {
                  font-size: 25px;
                  cursor: pointer;
                }
              }
            }
          }
        }
        &.toolDetails {
          .collapseCard__heading {
            padding: 10px 20px 10px;
            &.active {
              background-color: $color-light-gray;
            }
          }
          .collapseCard__body {
            background: $color-white;
            .list-info {
              color: red;
              &.disabled {
                user-select: none;
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.7;
              }
              .tool-details-timer-info-col1 {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                column-gap: 20px;
                max-width: 65%;
                min-width: 30%;
                @media (max-width: 1200px) {
                  max-width: 100%;
                  flex-wrap: wrap;
                }
                .tool-timer-name {
                  display: inline-flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  column-gap: 20px;
                  @media (max-width: 1200px) {
                    width: 100%;
                  }
                }
                .total-time-round {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  color: $color-primary;
                  background-color: #fff;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 15px;
                  padding: 5px 4px;
                  min-width: 25px;
                  border-radius: 50%;
                  background: radial-gradient(
                      closest-side,
                      white 75%,
                      transparent 80% 100%
                    ),
                    conic-gradient($color-primary 25%, rgb(200, 228, 239) 0);
                }
                .tool-details-name {
                  color: $color-primary-dark;
                  min-width: 250px;
                  width: 100%;
                }
                .tool-dependency-status {
                  display: inline-flex;
                  flex-direction: row;
                  column-gap: 6px;
                  align-items: center;
                  justify-content: flex-start;
                  width: 50%;
                  @media (max-width: 1200px) {
                    margin: 10px 0;
                  }
                  .tool-details-status {
                    display: inline-flex;
                    align-items: center;
                    font-size: 12px;
                    font-weight: 400;
                    color: $color-white;
                    width: 50px;
                    column-gap: 8px;
                    .onboarding-status {
                      display: inline-block;
                      background: $color-orange;
                      padding: 4px 4px;
                      border-radius: 4px;
                      width: 30px;
                      text-align: center;
                      font-size: 15px;
                      font-weight: 500;
                      line-height: 16px;
                      letter-spacing: 0em;
                      color: $color-white;
                    }
                    .support-team {
                      display: inline-block;
                      background: $color-primary-dark;
                      padding: 4px 4px;
                      border-radius: 4px;
                      width: 30px;
                      text-align: center;
                      font-size: 15px;
                      font-weight: 500;
                      line-height: 16px;
                      letter-spacing: 0em;
                      color: $color-white;
                    }
                    .icon-yet-to-launch {
                      color: $color-light-light-gray;
                      font-size: 15px;
                    }
                    .open {
                      display: inline-block;
                      width: fit-content;
                      padding: 2px 10px;
                      border-radius: 4px;
                      background-color: $color-primary-dark;
                    }
                    .in-progress {
                      display: inline-block;
                      width: fit-content;
                      padding: 2px 10px;
                      border-radius: 4px;
                      background-color: $color-orange-lighter;
                    }
                    .pause {
                      display: inline-block;
                      padding: 2px 10px;
                      width: fit-content;
                      border-radius: 4px;
                      background-color: $color-orange;
                    }
                    .completed,
                    .close {
                      display: inline-block;
                      padding: 2px 10px;
                      width: fit-content;
                      border-radius: 4px;
                      background-color: $color-light-green;
                    }
                  }
                  .dependency_required {
                    margin: 0;
                    width: 30px;
                    span {
                      display: inline-block;
                      background: $color-purple-dark;
                      padding: 4px 4px;
                      border-radius: 4px;
                      width: 100%;
                      text-align: center;
                      font-size: 15px;
                      font-weight: 500;
                      line-height: 16px;
                      letter-spacing: 0em;
                      color: $color-white;
                    }
                  }
                  .dependency_status {
                    .status-icons {
                      vertical-align: middle;
                      line-height: 15px;
                      font-size: 13px;
                    }
                  }
                }
              }
              .tool-details-timer-info-col2 {
                width: 35%;
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                column-gap: 10px;
                @media (max-width: 1200px) {
                  width: 100%;
                }
                .tool-assignee-info {
                  display: inline-flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  column-gap: 5px;
                  width: 70%;
                  .tool-assignee-list {
                    width: 70%;
                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    column-gap: 10px;
                    .tool-assignee {
                      min-width: 18px;
                    }
                    .tool-assignee-name {
                      font-size: 13px;
                      font-weight: 400;
                      line-height: 16px;
                      letter-spacing: 0em;
                      color: $color-black1;
                    }
                  }
                  .tool-assignee-dept {
                    width: fit-content;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: $color-light-black;
                    text-transform: capitalize;
                    text-align: center;
                  }
                  .display-icon {
                    display: inline-flex;
                    align-items: center;
                    border-radius: 50%;
                    // width: 18px;
                    // height: 18px;
                    fill: $color-white;
                    border: 1px solid $color-black;
                    vertical-align: middle;
                    &:first-child {
                      margin-left: 0;
                    }
                    .user-icon {
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      width: 18px;
                      height: 18px;
                      font-weight: 500;
                      font-size: 10px;
                      line-height: 100%;
                      text-align: center;
                      border-radius: 50%;
                      color: $color-white;
                    }
                  }
                  .name {
                    color: $color-gray;
                  }
                }

                .total-time {
                  display: inline-flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  column-gap: 10px;
                  width: 30%;
                  .ProgressBar {
                    max-width: 250px;
                    width: 40%;
                    padding: 0 2px;
                    // width: 50%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    p {
                      margin: 0;
                      font-size: 13px;
                      font-weight: 400;
                      color: #71d7ff;
                    }
                  }
                  .time-remaining {
                    display: none;
                  }
                  .avatars {
                    display: flex;
                    list-style-type: none;
                    margin: auto;
                    padding: 0px 7px 0px 0px;
                    align-items: center;
                    width: 60%;
                    .display-icon {
                      display: inline-flex;
                      align-items: center;
                      border-radius: 50%;
                      // width: 18px;
                      // height: 18px;
                      fill: var(--color-white);
                      border: 1px solid $color-black;
                      vertical-align: middle;
                      margin-left: -5px;
                      &:first-child {
                        margin-left: 0;
                      }
                      .user-icon {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: 18px;
                        height: 18px;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 100%;
                        text-align: center;
                        border-radius: 50%;
                        color: $color-white;
                      }
                    }
                    .avatars__others {
                      background-color: $color-light-gray;
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      height: 18px;
                      width: 18px;
                      font-size: 10px;
                      font-weight: 400;
                      line-height: 12.1px;
                      color: $color-primary-1;
                      border-radius: 50%;
                    }
                  }
                }
              }
            }
          }
        }
        &.moduleDetails,
        &.moduleDetailsInnerTree {
          margin: 5px 0;
          .collapseCard__heading {
            padding: 10px 20px 10px;
            &.active {
              background-color: transparent;
            }
            .moduleDetailsHeader {
              display: flex;
              justify-content: space-between;
              align-items: center;
              &-left {
                display: inline-flex;
                gap: 5px;
                align-items: center;
                .moduleHeader {
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: left;
                  color: $color-primary-dark;
                }
                .icon-chevron-thin-down {
                  display: inline-flex;
                  position: unset;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: left;
                  &::before {
                    font-size: 14px;
                    vertical-align: -2px;
                    font-weight: 700;
                  }
                }
              }
              .subModule {
                font-size: 16px;
                font-weight: 500;
                line-height: 21.6px;
                span {
                  text-align: center;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 21.6px;
                  color: $color-dark-gray-3;
                }
                .icon-chevron-thin-down {
                  &::before {
                    color: $color-dark-gray-3;
                    font-size: 14px;
                  }
                }
              }
              &-right {
                display: inline-flex;
                gap: 10px;
                align-items: center;
                &-createdBy{
                  font-size: 14px;
                  font-weight: 600;
                  color: $color-gray;
                  display: inline-flex;
                  gap: 6px;
                  .display-icon {
                    display: inline-flex;
                    align-items: center;
                    border-radius: 50%;
                    fill: $color-white;
                    vertical-align: middle;
                    .user-icon {
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      width: 20px;
                      height: 20px;
                      font-weight: 500;
                      font-size: 10px;
                      line-height: 100%;
                      text-align: center;
                      border-radius: 50%;
                      color: $color-white;
                    }
                  }
                  &-time{
                    font-weight: 400;
                    color: $color-oldSilver;
                  }
                }
                button {
                  background-color: unset;
                  border: 1px solid $color-primary-dark;
                  border-radius: 4px;
                  font-size: 14px;
                  font-weight: 400;
                  display: inline-flex;
                  align-items: center;
                  column-gap: 5px;
                  height: 30px;
                  padding: 2px 10px;
                  span {
                    font-size: 18px;
                  }
                }
              }
            }
          }
          .collapseCard__body {
            background: $color-white;
            .moduleDetailsContent {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: 12px 24px;
              border-bottom: 1px solid rgba(224, 224, 224, 1);
              // &-left{
              //   display: inline-flex;
              //   flex-direction: row;
              //   align-items: center;
              //   justify-content: flex-start;
              //   column-gap: 20px;
              //   max-width: 65%;
              //   min-width: 30%;
              // }
              // &-right{
              //   width: 35%;
              //   display: inline-flex;
              //   flex-direction: row;
              //   align-items: center;
              //   justify-content: flex-start;
              //   column-gap: 10px;
              // }

              .collapseCard {
                width: 100%;
                background: none;
                border: none;
                border-radius: 0;
                margin: 0;
                .collapseCard__heading {
                  padding: 0;
                }
                .collapseCard__body {
                  background: $color-white;
                  border-top: unset;
                }
              }
              .parentModuleDetails,
              .childModuleDetails {
                width: 100%;
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                gap: 5px;
                .parentModuleDetailsContent,
                .childModuleDetailsContent {
                  display: inline-flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  &-left {
                    width: 60%;
                    padding-left: 20px;
                    span {
                      font-size: 16px;
                      font-weight: 500;
                      line-height: 21.6px;
                      color: $color-dark-gray-3;
                    }
                  }
                  &-right {
                    width: 40%;
                    display: inline-flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    gap: 10px;
                    &-status {
                      display: inline-flex;
                      justify-content: flex-end;
                      align-items: flex-start;
                      width: 80%;
                      padding: 0 5px 0 0;
                      .parentModule-status_history {
                        font-size: 12px;
                        font-weight: 400;
                        color: $color-dark-gray-7;
                        padding: 0 20px 0 0;
                      }
                      .icon-info {
                        font-size: 20px;
                      }
                      .showToolTip.module-histroy-popup {
                        width: 21px;
                        height: 20px;

                        .popup-content {
                          top: 50%;
                          left: 30px;
                          min-width: 300px;
                          max-width: 450px;
                          border-radius: 6px;
                          transform: translateY(-50%);
                          .arrow-before {
                           content: " ";
                          }
                          .arrow-after {
                            content: " ";
                          }
                          @media (max-width: 1100px) {
                            & {
                              right: 30px;
                              left: inherit;
                              .arrow-before {
                                right: -14px;
                                left: inherit;
                                border-color: transparent transparent transparent rgba(27, 31, 53, 0.12);
                               }
                               .arrow-after {
                                right: -12px;
                                left: inherit;
                                border-color: transparent transparent transparent $color-white;
                               }
                            }
                          }
                          .showPopup {
                            .tip-entry-main .tip-entry {
                              font-size: 12px;
                              font-weight: 400;
                              line-height: 18px;
                              text-align: left;
                              color: $color-darkLiver;
                              border-bottom: none;
                              padding: 4px 12px 4px 12px;
                              user-select: none;
                              pointer-events: none;
                              &:last-child {
                                padding-bottom: 8px;
                              }
                            }
                          }
                        }
                      }
                    }
                    &-more {
                      display: inline-flex;
                      justify-content: flex-end;
                      align-items: center;
                      width: 20%;
                      font-size: 20px;
                    }
                  }
                }
                .childModuleDetailsContent {
                  &-left {
                    .childModule-name {
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 18px;
                      color: $color-dark-gray-3;
                    }
                  }
                  &-right {
                    &-status {
                      .childModule-status_history {
                        font-size: 12px;
                        font-weight: 400;
                        color: $color-dark-gray-7;
                        padding: 0 20px 0 0;
                      }
                      .icon-info {
                        font-size: 20px;
                        background: none;
                        padding: 0;
                        border: none;
                        &::before {
                          color: $color-black;
                          font-size: 22px;
                        }
                      }
                    }
                    &-more {
                      display: inline-flex;
                      justify-content: flex-end;
                      align-items: center;
                      width: 30px;
                      font-size: 20px;
                      .showToolTip.module-status-popup {
                        .popup-content {
                          top: 50%;
                          left: 30px;
                          min-width: 260px;
                          max-width: 400px;
                          border-radius: 6px;
                          transform: translateY(-50%);
                          .arrow-before {
                           content: " ";
                          }
                          .arrow-after {
                            content: " ";
                          }
                          @media (max-width: 1100px) {
                            & {
                              right: 30px;
                              left: inherit;
                              .arrow-before {
                                right: -14px;
                                left: inherit;
                                border-color: transparent transparent transparent rgba(27, 31, 53, 0.12);
                               }
                               .arrow-after {
                                right: -12px;
                                left: inherit;
                                border-color: transparent transparent transparent $color-white;
                               }
                            }
                          }
                          .showPopup {
                            padding: 5px 0;
                            .tip-entry-main .tip-entry {
                              font-size: 15px;
                              font-weight: 400;
                              line-height: 18px;
                              text-align: left;
                              color: $color-gray;
                              border-bottom: none;
                              padding: 8px 15px;
                              &.active {
                                user-select: none;
                                pointer-events: none;   
                              }
                              &:last-child {
                                padding-bottom: 8px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .childModuleDetails {
                padding: 5px 0 5px 25px;
              }
            }
            // :last-child {
            //   border-bottom: unset;
            // }
          }
        }
        &.moduleDetails {
          box-shadow: 0px 2px 4px 0px rgba(27, 31, 53, 0.12);
          border: unset;
          margin: 10px 0;
        }
      }
      .popupModal__container.create-edit-iteration .popupModal__root {
        min-width: 735px;
        .popupModal__heading {
          padding: 12px 12px;
          margin: 0;
          h2 {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            text-decoration-skip-ink: none;
            color: $color-primary-dark;
            margin: 0;
            width: 100%;
          }
          .icon-close {
            display: none;
          }
          .create-edit-iteration-header {
            width: 100%;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            &-delete {
              display: inline-flex;
              align-items: center;
              gap: 4px;
              padding: 6px 16px;
              background: $color-light-red;
              border-radius: 4px;
              color: $color-light-ghost-white;
              font-size: 15px;
              border: unset;
              span {
                font-size: 18px;
              }
            }
          }
        }
        .popupModal__body {
          .add-edit-iteration {
            padding: 6px 12px;
            display: inline-flex;
            flex-direction: column;
            width: 100%;
            gap: 12px;
            &-input {
              width: 100%;
              padding: 8px 12px;
              border: 1px solid $color-light-light-gray-5;
              border-radius: 4px;
              font-size: 14px;
            }
            &-divider {
              border-top: 1px solid #c4c4c4;
            }
            &-module-section {
              display: inline-flex;
              flex-direction: column;
              gap: 12px;
              p {
                font-size: 14px;
                font-weight: 600;
                line-height: 19.2px;
                color: #656565;
                margin: 0;
              }
              &-list {
                list-style-type: none;
                padding: 0;
                margin: 0;
                ul {
                  list-style-type: none;
                }
                li {
                  margin-bottom: 8px;
                  label {
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 600;
                    color: #6c6c6c;
                    margin-bottom: 8px;
                  }
                }
              }
            }
            &-footer {
              display: flex;
              gap: 12px;
              align-items: center;
              justify-content: flex-end;
              font-size: 13px;
              font-weight: 400;
              &-submit {
                border-radius: 24px;
                line-height: 18px;
                background: $color-primary-dark;
                color: $color-white;
                padding: 6px 16px;
                border: none;
                cursor: pointer;
              }
              &-cancel {
                line-height: normal;
                border-radius: 24px;
                color: $color-oldSilver;
                border: 1px solid $color-oldSilver;
                background: $color-white;
                padding: 6px 16px;
              }
            }
          }
        }
      }
    }
    &_ticketDescription {
      width: 100%;
      .flex-heading {
        display: flex;
        justify-content: flex-start;
        padding: 0;
        align-items: baseline;
        column-gap: 8px;
        flex-direction: column;
      }
      &_head {
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: $color-darkLiver;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin: 0 0 8px !important;
        .colan {
          font-size: 16px;
        }
        .icon-description {
          font-size: 14px;
        }
        p:first-child {
          font-weight: 500;
          color: $color-black;
        }
      }
      &_content {
        font-size: 13px;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: $color-dark-gray-2;
        margin: 0 0 20px;
        background: $color-light-ghost-white;
        padding: 10px 20px;
        border-radius: 8px;
        width: 100%;
      }
      &_paragraph {
        p {
          font-size: 13px;
          font-weight: 300;
          line-height: 18px;
          letter-spacing: 0.1px;
          color: $color-darkLiver;
          margin: 0;
          strong {
            font-weight: 600;
          }
        }
      }
      p,
      ul {
        margin: 0;
      }
    }

    .overview-content {
      margin: 10px 0;
      .heading {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        display: flex;
        color: $color-primary-dark;
        justify-content: space-between;
        margin: 10px 0 0;
        align-items: center;
        .overview-edit {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: $color-light-light-gray-3;
          background: none;
          border: none;
        }
      }

      .overview-body-content {
        background: $color-light-gray-3;
        padding: 30px 18px;
        border-radius: 8px;
        &-col-3 {
          display: flex;
          gap: 100px;
          flex-wrap: wrap;
          &-container {
            display: inline-flex;
            flex-direction: column;
            gap: 18px;
            max-width: 550px;
            h1 {
              color: $color-primary;
              font-size: 17px;
              font-weight: 600;
              line-height: 21px;
              margin: 0;
            }
            .overview-info {
              list-style: none;
              margin: 0;
              padding: 0;
              display: flex;
              flex-direction: column;
              column-gap: 30px;
              row-gap: 24px;
              flex-wrap: wrap;
              li {
                display: inline-flex;
                // flex-direction: column;
                flex-wrap: nowrap;
                width: fit-content;
                column-gap: 8px;
                padding: 0;
                list-style-type: none;
                font-size: 14px;
                line-height: 18px;
                .data-heading {
                  font-size: 15px;
                  line-height: 20px;
                }
                .colan {
                  font-size: 15px;
                  line-height: 20px;
                }
                .value {
                  font-size: 15px;
                  line-height: 20px;
                  font-weight: 500;
                  color: $color-primary-dark;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
            .data-heading {
              font-size: 16px;
              font-weight: 600;
              line-height: 20px;
              text-align: left;
              color: $color-primary;
              margin: 0 0 10px;
            }
            .data-title {
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              text-align: left;
              margin: 0 0 6px;
              overflow: hidden;
              text-overflow: ellipsis;
              color: $color-primary-dark;
              strong {
                font-weight: 400;
                color: $color-dark-gray-6;
              }
            }
          }
        }
        .company-overview-content {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: row;
          column-gap: 30px;
          row-gap: 30px;
          flex-wrap: wrap;
          li {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: fit-content;
            column-gap: 8px;
            padding: 0;
            list-style-type: none;
            font-size: 14px;
            line-height: 18px;
            // margin: 30px 0 0px;
            .data-heading {
              font-size: 15px;
              line-height: 20px;
            }
            .colan {
              font-size: 15px;
              line-height: 20px;
            }
            .value {
              font-size: 15px;
              line-height: 20px;
              font-weight: 500;
              color: $color-primary-dark;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .checklist-content {
      margin: 10px 0 20px;
      .heading {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        display: flex;
        align-items: center;
        color: $color-primary-dark;
        justify-content: space-between;
        margin: 10px 0;
        .checklist-edit {
          font-size: 13px;
          font-weight: 500;
          line-height: 15.73px;
          text-align: left;
          border-radius: 4px;
          background: $color-white;
          border: 1px solid $color-light-gray-4;
          color: $color-primary-dark;
          height: 28px;
        }
      }
      .checklist-body-content {
        background: transparent;
        padding: 0;
        margin: 0;
        .checklist-tools-content {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          justify-content: space-between;
          align-items: center;
          list-style-type: none;
          padding: 0;
          margin: 0;
          width: 100%;
          border-radius: 8px;
          border: 1px solid $color-light-light-gray;
          li {
            width: 100%;
            list-style-type: none;
            background: $color-white;
            padding: 12px 24px 12px 24px;
            margin: 0 0;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            column-gap: 8px;
            border-top: 1px solid $color-light-light-gray;
            &.display-flex-column {
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              row-gap: 8px;
              border: none;
              padding: 0px 70px 12px 30%;

              .upcoming-release-first-row {
                width: 97%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                column-gap: 25px;
                flex-wrap: wrap;
                margin: 0 0 0 5px;
                @media (max-width: 1300px) {
                  width: 100%;
                }
                .upcoming-release-col1 {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-start;
                  column-gap: 10px;
                  margin: 0 0 4px;
                  .heading {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-9;
                    margin: 0;
                    width: 100%;
                    text-align: left;
                  }
                  .data {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-10;
                    margin: 0;
                    width: 84px;
                    text-align: left;
                  }
                }
                .upcoming-release-col2 {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-start;
                  column-gap: 10px;
                  margin: 0 0 4px;
                  .heading {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-9;
                    margin: 0;
                    width: 180px;
                    text-align: left;
                  }
                  .data {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-10;
                    margin: 0;
                    max-width: 40px;
                    min-width: 40px;
                    text-align: left;
                    @media (max-width: 1290px) {
                      min-width: 40px;
                    }
                  }
                }
              }
              .upcoming-release-second-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                column-gap: 10px;
                width: 97%;
                flex-wrap: wrap;
                margin: 0 0 0 5px;
                @media (max-width: 1300px) {
                  width: 100%;
                }
                .upcoming-release-col1 {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-start;
                  column-gap: 10px;
                  margin: 0 0 4px;
                  .heading {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-9;
                    margin: 0;
                    width: 115px;
                    text-align: left;
                  }
                  .data {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-10;
                    margin: 0;
                    width: 200px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: left;
                  }
                }
                .upcoming-release-col2 {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  column-gap: 10px;
                  margin: 0 0 4px;
                  width: 315px;
                  overflow: hidden;
                  .heading {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-9;
                    margin: 0;
                    width: 100px;
                    text-align: left;
                  }
                  .data {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-10;
                    margin: 0;
                    max-width: 205px;
                    min-width: 100px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
              .upcoming-release-third-row {
                width: 97%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                column-gap: 10px;
                flex-wrap: wrap;
                margin: 0 0 0 5px;
                .upcoming-release-col1 {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-start;
                  column-gap: 10px;
                  margin: 0 0 4px;
                  .heading {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-9;
                    margin: 0;
                    width: 80px;
                    text-align: left;
                  }
                  .data {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-10;
                    margin: 0;
                    max-width: 320px;
                    min-width: 100px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
                .upcoming-release-col2 {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-end;
                  column-gap: 10px;
                  margin: 0 0 4px;
                  .heading {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-9;
                    margin: 0;
                    width: 205px;
                    text-align: right;
                  }
                  .data {
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: $color-light-gray-10;
                    margin: 0;
                    max-width: 205px;
                    min-width: fit-content;
                    text-align: left;
                  }
                }
              }
            }
            &:first-child {
              border-radius: 8px 8px 0 0;
            }
            &:last-child {
              border-radius: 8px 8px 8px 8px;
            }
            .first-col {
              display: inline-flex;
              flex-direction: row;
              column-gap: 15px;
              align-items: center;
              flex-wrap: wrap;
              width: 30%;
              .tool-selected {
                border: none;
                background: none;
                padding: 0;
                &:disabled {
                  opacity: 0.2;
                  cursor: default;
                }
                .checkedIcon {
                  display: block;
                  width: 16px;
                  height: 16px;
                }
              }

              .tool-name {
                display: block;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: $color-dark-gray-3;
                &.completed {
                  text-decoration: line-through;
                }
              }
            }
            .second-col {
              display: inline-flex;
              flex-direction: row;
              gap: 8px;
              column-gap: 14px;
              align-items: center;
              flex-wrap: wrap;
              width: 35%;
              .current_release_date {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                color: $color-light-gray-9;
                margin: 0;
                .date {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18px;
                  color: $color-light-gray-10;
                }
              }
              .selected_quatre_list {
                list-style: none;
                display: flex;
                flex-direction: row;
                column-gap: 8px;
                align-items: center;
                padding: 0;
                margin: 0;
                flex-wrap: wrap;
                li {
                  padding: 0;
                  list-style-type: none;
                  width: fit-content;
                  padding: 2px 8px;
                  color: $color-white;
                  border-radius: 50px;
                  font-size: 11px;
                  font-weight: 400;
                  line-height: 14px;
                }
              }
            }
            .third-col {
              display: inline-flex;
              flex-direction: row;
              column-gap: 15px;
              align-items: center;
              justify-content: flex-end;
              width: 35%;
              flex-wrap: wrap;
              .assignee,
              .tool-due-date {
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                text-align: left;
                color: $color-light-gray-6;
              }
              .assignee {
                display: inline-flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
                flex-wrap: wrap;
                .display-icon {
                  display: inline-flex;
                  align-items: center;
                  border-radius: 50%;
                  fill: var(--color-white);
                  border: 1px solid $color-primary;
                  vertical-align: middle;
                  .user-icon {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 18px;
                    height: 18px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 100%;
                    text-align: center;
                    border-radius: 50%;
                    color: $color-white;
                  }
                }
                .showToolTip {
                  &.updateTeamToolAssignee {
                    width: 20px;
                    height: 15px;
                    .popup-content {
                      min-width: 224px;
                      padding: 12px 6px 12px 12px;
                      z-index: 100;
                      .toollist_search {
                        position: relative;
                        padding: 0 0 12px;
                        border-bottom: 1px solid $color-oldSilver;
                        margin: 0 0 6px;
                        .search-label-input {
                          width: 100%;
                          border: 1px solid $color-light-light-gray;
                          border-radius: 8px;
                          height: 34px;
                          padding: 10px 31px 10px 12px;
                        }
                        .searchIcon {
                          position: absolute;
                          right: 10px;
                          top: 8px;
                        }
                      }
                      .tip-entry-main {
                        width: 100%;
                        height: 111px !important;
                        overflow: auto;
                        &::-webkit-scrollbar {
                          width: 6px;
                          height: 2px;
                          background-color: $color-light-light-gray;
                          border-radius: 10px;
                        }
                        &::-webkit-scrollbar-track {
                          -webkit-box-shadow: inset 0 0 4px
                            $color-light-light-gray;
                          border-radius: 10px;
                          background-color: $color-light-light-gray;
                        }
                        &::-webkit-scrollbar-thumb {
                          border-radius: 10px;
                          -webkit-box-shadow: inset 0 0 4px $color-oldSilver;
                          background-color: $color-oldSilver;
                        }
                        .tip-entry {
                          background: $color-white;
                          color: $color-darkLiver;
                          font-size: 12px;
                          font-weight: 400;
                          line-height: 14px;
                          border-radius: 0;
                          padding: 10px 12px;
                          &.error-message {
                            color: $color-light-red;
                            font-weight: 500;
                          }
                        }
                      }
                    }
                    .icon-user-switch{
                      font-size: 18px;
                    }
                  }
                }
              }
              .icon-time_empty {
                display: block;
                font-size: 18px;
                line-height: 20px;
              }
              .name,
              .date {
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                text-align: left;
                color: $color-black;
              }
              .editIcon_actions {
                border: none;
                background: none;
                width: 17px;
                padding: 0;
                &:disabled {
                  opacity: 0.5;
                }
                img {
                  vertical-align: -3px;
                }
                span{
                  font-size: 18px;
                }
              }
              .due-date-col {
                position: relative;
                display: inline-flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
                .picker-date {
                  .rdtPicker {
                    z-index: 104;
                    position: absolute;
                    // .button-container button:first-child {
                    //   display: none;
                    // }
                  }
                }
              }
            }
            .fourth-col {
              display: inline-flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              width: 17px;
              .icon-chevron-thin-down {
                cursor: pointer;
                line-height: 12px;
                &::before {
                  font-size: 12px;
                  vertical-align: -2px;
                  font-weight: 700;
                }
                &.active {
                  line-height: 17px;
                  transform: rotate(180deg);
                  -webkit-transform: rotate(180deg);
                  -moz-transform: rotate(180deg);
                  -ms-transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }

    .agency-overview-content {
      border: 1px solid $color-light-light-gray-1;
      padding: 10px 15px;
      border-radius: 8px;
      margin: 20px 0 15px;
      .heading-flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 3px 0 25px;
        .addUpdateRequirement {
          width: 82px;
          height: 34px;
          padding: 8px 10px 8px 10px;
          border-radius: 4px;
          border: 1px solid $color-primary-dark;
          color: $color-darkLiver;
          background: none;
          font-size: 15px;
          font-weight: 400;
          line-height: 18.15px;
          text-align: center;
          &:disabled {
            user-select: none;
            pointer-events: none;
            opacity: 0.5;
          }
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
      .agency_heading {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        display: flex;
        color: $color-primary-dark;
        justify-content: space-between;
        margin: 0 0 10px;
        align-items: center;
      }
      .confirm-ticketbody {
        width: 100%;
        &__header {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        h2 {
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-align: left;
          margin: 0;
          text-transform: capitalize;
          color: $color-blue-4;
          & .heading b {
            color: $color-blue-4;
          }
        }
        &__update-text {
          text-decoration: underline;
          font-weight: 500;
          color: $color-blue-4;
        }
        &__confirm-text {
          color: #828282;
          margin-top: 5px;
          font-size: 15px;
        }
        &__preview-box {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        &__tab-box {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 15px;
          flex-wrap: wrap;
        }
        .triangle-box {
          position: relative;
          background: $color-light-ghost-white; /* Button color */
          border: 1px solid #082b45;
          text-align: center;
          padding: 6px 20px; /* Adjusted padding for better appearance */
          border-radius: 5px; /* Optional: rounded corners for smoother look */
          display: inline-block; /* Ensures proper box sizing */
          cursor: pointer; /* Make it clickable */
          z-index: 1; /* Keep the box above the triangle */
          font-weight: 600;
        }
        .triangle-box1 {
          content: "";
          position: absolute;
          left: 50%;
          bottom: -10px; /* Places triangle just below the button */
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #082b45; /* Same color as the button */
          z-index: 0; /* Behind the button */
        }
        &__footer_row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          margin-top: 1%;
          .cancel-form {
            width: 85px;
            height: 29px;
            border: 1px solid $color-oldSilver;
            color: $color-oldSilver;
            border-radius: 24px;
            background: $color-white;
            font-size: 14px;
          }
          .next-btn {
            width: 80px;
            height: 29px;
            padding: 0px 16px 2px;
            border-radius: 24px;
            background: $color-blue-4;
            color: $color-white;
            border: none;
            display: block;
            text-align: center;
            position: relative;
            font-size: 14px;
            &:disabled {
              opacity: 0.5;
            }
            &.loading {
              background: $color-primary-dark;
              padding-right: 40px;
              color: $color-white;
              width: 110px;
            }
            &.loading::before {
              content: "";
              position: absolute;
              border-radius: 100%;
              right: 6px;
              top: 50%;
              width: 0px;
              height: 0px;
              margin-top: -2px;
              border: 2px solid rgba(255, 255, 255, 0.5);
              border-left-color: #fff;
              border-top-color: #fff;
              animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
            }
            @keyframes spin {
              to {
                transform: rotate(359deg);
              }
            }
            @keyframes grow {
              to {
                width: 14px;
                height: 14px;
                margin-top: -8px;
                right: 13px;
              }
            }
          }
          .create-btn {
            width: 81px;
            height: 29px;
            padding: 0px 16px 2px;
            border-radius: 24px;
            background: $color-blue-4;
            color: $color-white;
            border: none;
            display: block;
            text-align: center;
            position: relative;
            &.loading {
              background: $color-primary-dark;
              padding-right: 40px;
              color: $color-white;
              width: 105px;
            }
            &.loading::before {
              content: "";
              position: absolute;
              border-radius: 100%;
              right: 6px;
              top: 50%;
              width: 0px;
              height: 0px;
              margin-top: -2px;
              border: 2px solid rgba(255, 255, 255, 0.5);
              border-left-color: #fff;
              border-top-color: #fff;
              animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
            }
            @keyframes spin {
              to {
                transform: rotate(359deg);
              }
            }
            @keyframes grow {
              to {
                width: 14px;
                height: 14px;
                margin-top: -8px;
                right: 13px;
              }
            }
          }
        }
        &__render-box {
          width: 100%;
          margin-top: 2%;
          // padding: 2%;
          border-radius: 10px;
          &::-webkit-scrollbar {
            width: 6px;
            height: 2px;
            background-color: $color-light-light-gray;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 4px $color-light-light-gray;
            border-radius: 10px;
            background-color: $color-light-light-gray;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 4px $color-oldSilver;
            background-color: $color-oldSilver;
          }
          .general-info {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 7px 10px 12px;
            gap: 10px;
            background-color: $color-primary-light-3;
          }
          .Design-info {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 7px 10px 12px;
            column-gap: 10px;
            gap: 2px;
            background-color: $color-primary-light-3;
            .column-row {
              width: 19%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .variable_name {
                color: $color-light-gray-11;
                padding: 0;
                margin: 0 0 8px;
              }
              .name {
                color: $color-blue-4;
              }
              .value_text {
                color: $color-primary-dark;
                font-weight: 600;
                font-size: 16px;
                padding: 0;
                margin: 2px 0 0 0;
                max-width: 180px;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .secondary_market_box {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
              }
              .array_map_box {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-start;
              }
            }
          }
          .column-row {
            width: 19%;
            flex: 0 1 calc(20% - 16px); /* 5 columns */
          }
          .column-full {
            min-width: 19%;
          }
          .column-row,
          .column-full {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &.notes-detail {
              width: 100%;
              .value_text {
                font-size: 14px;
                color: $color-primary-dark;
                font-weight: 400;
                line-height: 18px;
              }
            }
            .variable_name {
              color: $color-light-gray-11;
              padding: 0;
              margin: 0 0 8px;
              font-size: 12px;
              font-weight: 400;
              line-height: 14.4px;
            }
            .name {
              color: $color-blue-4;
            }
            .value_text {
              width: 100%;
              color: $color-primary-dark;
              padding: 0;
              margin: 0;
              font-size: 16px;
              font-weight: 600;
              line-height: 19.2px;
              p {
                width: 100%;
                color: inherit;
                padding: 0;
                margin: 0;
                font-size: inherit;
              }
              &.overflow-text {
                max-width: -webkit-fill-available;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
            a.value_text {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              text-wrap: nowrap;
            }
            .variable_list {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-items: flex-start;
              column-gap: 8px;
              gap: 2px;
            }
            &.tool-List {
              width: 100%;
              margin: 25px 0 0 0;
              .variable_name {
                font-size: 14px;
                font-weight: 600;
                line-height: 16.8px;
                color: $color-gray;
                margin: 0 0 8px;
              }
            }
          }

          .custom-specification {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            &__header_row {
              background-color: $color-blue-4;
              width: 100%;
              display: flex;
              flex-direction: row;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }
            &__variable_column {
              width: 75%;
              padding: 1%;
              display: flex;
              align-items: center;
              flex-direction: row;
              p {
                font-weight: 500;
              }
            }
            &__value_column {
              width: 25%;
              padding: 1%;
              display: flex;
              align-items: center;
              flex-direction: row;
              justify-content: center;
              p {
                font-weight: 500;
              }
            }
            p {
              padding: 0;
              margin: 0;
              color: $color-dark-gray-6;
              &.not-applicable {
                color: $color-dark-gray-6;
              }
              &.required {
                color: $color-dark-green-1;
              }
              &.not-required {
                color: $color-light-red;
              }
            }
          }
        }
      }
    }
    .tools-content {
      margin: 10px 0 20px;
      .heading {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: $color-primary-dark;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .showToolTip.fYearList-popup {
          position: relative;
          width: 130px;
          padding: 4px 8px 4px 30px;
          background: $color-white;
          border: 1px solid $color-light-ghost-white;
          border-radius: 6px;
          -webkit-box-shadow: 1px 2px 7px 0px $color-light-light-gray;
          box-shadow: 1px 2px 7px 0px $color-light-light-gray;
          &::before {
            content: "FY";
            position: absolute;
            top: 6px;
            left: 10px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: $color-oldSilver;
          }
          .update-status {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.005em;
            text-align: left;
            text-decoration-skip-ink: none;
            color: $color-oldSilver;
          }
          .popup-content {
            width: 100%;
            max-height: 150px;
            border-radius: 4px;
            .tip-entry-main {
              padding: 10px;
              .tip-entry {
                border-radius: 0px;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                text-align: left;
                text-decoration-skip-ink: none;
              }
            }
          }
        }
        .checklist-edit {
          font-size: 13px;
          font-weight: 500;
          line-height: 15.73px;
          text-align: left;
          border-radius: 4px;
          background: $color-white;
          border: 1px solid $color-light-gray-4;
          color: $color-primary-dark;
          height: 28px;
        }
      }
      .tools-body-content {
        background: transparent;
        padding: 0;
        margin: 0;
        .tools-content {
          display: flex;
          flex-direction: column;
          row-gap: 0;
          justify-content: space-between;
          align-items: center;
          list-style-type: none;
          padding: 0;
          margin: 0;
          width: 100%;
          /*background: $color-light-gray-5;*/
          border-radius: 8px;
          border: 1px solid $color-light-light-gray;
          .showQuaterList {
            margin: 0;
            width: 100%;
            border: none;
            padding: 0;
            border-radius: 8px 8px 0 0;
            border-bottom: 1px solid $color-light-light-gray;
            &:last-child {
              border-bottom: none;
              border-radius: 0 0 8px 8px;
            }
            .collapseCard__heading {
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              &.active {
                background: none;
                color: $color-primary-dark;
                /*.current-release {
                  display: none;
                }*/
              }
              .tool_name {
                width: 44%;
              }
              .current-release {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                color: $color-light-gray-9;
                width: 220px;
                b {
                  font-weight: 400;
                  line-height: 18px;
                  color: $color-light-gray-10;
                }
              }
              .icon-chevron-thin-down {
                position: absolute;
                right: 15px;
                width: 20px;
                &:before {
                  color: $color-black;
                }
              }
            }
            .collapseCard__body.show {
              padding: 1.125rem;
              .selected_quatre_list {
                list-style: none;
                display: flex;
                flex-direction: row;
                column-gap: 20px;
                li {
                  list-style-type: none;
                  width: fit-content;
                  padding: 2px 8px;
                  color: $color-white;
                  border-radius: 4px;
                }
              }
            }
          }
        }
        .tools-release-content {
          background: transparent;
          padding: 8px 8px 15px;
          margin: 0;
          border-radius: 0 0 8px 8px;
          border-bottom: 1px solid $color-light-light-gray;
          overflow-x: auto;
          &::-webkit-scrollbar {
            width: 10px;
            height: 8px;
            background-color: $color-light-light-gray;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 8px $color-light-light-gray;
            border-radius: 10px;
            background-color: $color-light-light-gray;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 8px $color-oldSilver;
            background-color: $color-oldSilver;
          }
          .tools-release-history-list {
            display: table;
            width: 100%;
            list-style-type: none;
            margin: 0;
            padding: 0;
            position: relative;
            li {
              width: 25%;
              display: table-cell;
              list-style-type: none;
              margin: auto 0;
              padding: 0 20px 0;
              position: relative;
              min-height: 150px;
              text-align: center;
              &.first-child {
                width: 20%;
                text-align: left;
              }
              &::before {
                content: " ";
                position: absolute;
                top: 0;
                bottom: 0;
                height: 100%;
                right: 0;
                width: 1px;
                background: $color-light-light-gray;
              }
              &.last-child {
                width: 20%;
                text-align: right;
                &::before {
                  content: none;
                }
              }
              &.error-message {
                color: $color-light-red;
                text-align: center;
              }
              .tool-lists {
                width: 180px;
                display: inline-flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .tool-header {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  text-decoration-skip-ink: none;
                  color: $color-primary-dark;
                  margin: 0;
                  padding: 0;
                }
                .tool-item {
                  display: inline-flex;
                  flex-direction: row;
                  // justify-content: space-between;
                  justify-content: flex-start;
                  align-items: baseline;
                  gap: 10px;
                  width: 100%;
                  margin: 0;
                  padding: 8px 0;
                  border-bottom: 1px solid $color-light-light-gray;
                  &:last-child {
                    border: none;
                  }
                  .release-tool-name {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                    text-decoration-skip-ink: none;
                    margin: 0;
                    padding: 0;
                    color: $color-oldSilver;
                    width: 50px;
                  }
                  .tool-historyDates {
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    .history-date {
                      margin: 0 0 0px;
                      padding: 0;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 18px;
                      text-align: left;
                      text-decoration-skip-ink: none;
                    }
                  }
                  .history-wise-source {
                    position: relative;
                    margin: auto 0;
                    padding: 0;
                    .source-link-button {
                      border: none;
                      background: none;
                      padding: 0;
                      margin: 0;
                      height: 100%;
                    }

                    .popupModal__container.source-link {
                      .popupModal__root {
                        padding: 0;
                        min-width: 420px;
                        min-height: 150px;
                        border-radius: 6px;
                        .popupModal__heading {
                          background: $color-primary-dark;
                          padding: 15px 20px;
                          margin: 0;
                          h2 {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 20px;
                            text-align: left;
                            text-decoration-skip-ink: none;
                            color: $color-white;
                            margin: 0;
                          }
                          .icon-close {
                            color: $color-white;
                            top: 18px;
                            right: 20px;
                          }
                        }
                        .popupModal__body {
                          border-radius: 6px;
                          .source-link-popup {
                            padding: 15px 20px;
                            max-height: calc(100vh - 150px);
                            overflow-y: auto;
                            &::-webkit-scrollbar {
                              width: 6px;
                              height: 2px;
                              background-color: $color-light-light-gray;
                              border-radius: 10px;
                            }
                            &::-webkit-scrollbar-track {
                              -webkit-box-shadow: inset 0 0 4px
                                $color-light-light-gray;
                              border-radius: 10px;
                              background-color: $color-light-light-gray;
                            }
                            &::-webkit-scrollbar-thumb {
                              border-radius: 10px;
                              -webkit-box-shadow: inset 0 0 4px $color-oldSilver;
                              background-color: $color-oldSilver;
                            }
                            .text-center {
                              text-align: center;
                            }
                            .history-date {
                              margin: 0;
                              padding: 0;
                              .source-list {
                                margin: 8px 0;
                                padding: 8px 0;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                                border-bottom: 1px solid $color-light-light-gray;
                                .sourceName {
                                  font-size: 12px;
                                  font-weight: 400;
                                  line-height: 14px;
                                  text-align: center;
                                  text-decoration-skip-ink: none;
                                }
                                .sourcelink {
                                  font-size: 12px;
                                  font-weight: 400;
                                  line-height: 14px;
                                  text-align: center;
                                  text-decoration-skip-ink: none;
                                }
                              }
                              &:last-child {
                                .source-list {
                                  &:last-child {
                                    border: none;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .agency-tools-content {
      .heading {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        display: flex;
        align-items: center;
        color: $color-primary-dark;
        justify-content: space-between;
        margin: 10px 0 0;
        border: 1px solid $color-light-light-gray;
        padding: 12px 15px;
        border-radius: 5px 5px 0px 0px;
        border-bottom: none;
        .checklist-edit {
          font-size: 13px;
          font-weight: 500;
          line-height: 15.73px;
          text-align: left;
          border-radius: 4px;
          background: $color-white;
          border: 1px solid $color-light-gray-4;
          color: $color-primary-dark;
          height: 28px;
        }
      }
      .tools-body-content {
        background: transparent;
        padding: 0;
        margin: 0;
        .agency-tools-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &-list {
            padding: unset;
            border: 1px solid $color-light-light-gray;
            font-size: 15px;
            font-weight: 400;
            color: $color-dark-gray-3;
            border-radius: 0 0 5px 5px;
            li {
              padding: 12px 24px 12px 24px;
              border-radius: 8px;
              margin: 0 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              border-radius: unset;
              &.error-message {
                display: block;
                text-align: center;
                color: $color-light-red;
              }
            }
            li:not(:last-child) {
              border-bottom: 1px solid $color-light-light-gray;
            }
            &-name {
              display: inline-flex;
              gap: 8px;
              align-items: center;
              cursor: pointer;
              img {
                width: 16px;
                height: 16px;
              }
            }
            .completed {
              text-decoration: line-through;
            }
            &-status {
              font-size: 13px;
              display: flex;
              gap: 13px;
              align-items: center;

              .icon-success-tick {
                font-size: 24px;
              }
              .icon-close {
                background-color: $color-light-red;
                font-size: 12px;
                color: $color-white;
                border-radius: 50px;
                padding: 4px;
              }
            }
            &.disabled {
              user-select: none;
              opacity: 0.5;
              pointer-events: none;
              cursor: not-allowed;
            }
          }
        }
      }
    }
    .attachments-content {
      margin: 10px 0 20px;
      .heading {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        display: flex;
        align-items: center;
        color: $color-primary-dark;
        justify-content: space-between;
        margin: 10px 0 0;
        border: 1px solid $color-platinum;
        padding: 12px 15px;
        border-radius: 5px 5px 0px 0px;
        border-bottom: none;
        .icon-upload_file,
        .icon-attachment {
          font-size: 21px;
          font-weight: 500;
          line-height: 15.73px;
          text-align: left;
          background: none;
          border: none;
          padding: 0;
        }
      }
      .ticket_info_container_updatedDetails {
        display: block;
        background: $color-light-gray;
        border: 1px solid $color-platinum;
        border-radius: 0px 0px 5px 5px;
        margin: 0 0;
        position: relative;
        font-size: 15px;
        color: $color-darkLiver;
        .file-info {
          padding: 12px 45px 12px 12px;
          .file-info-container {
            display: flex;
            flex-direction: row;
            column-gap: 8px;
            justify-content: space-between;
            align-items: center;
            .name {
              display: inline-flex;
              flex-direction: row;
              column-gap: 10px;
              align-items: center;
              span:first-child {
                font-size: 32px;
              }
            }
            .flex-data {
              display: inline-flex;
              flex-direction: row;
              column-gap: 15px;
              align-items: center;
              .icon-download_file {
                cursor: pointer;
                font-size: 25px;
              }
              .icon-close {
                color: var(--color-light-red);
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  &_right-panel {
    position: fixed;
    top: 70px;
    bottom: 0;
    right: 0;
    transition: width 0.3s;
    background: $color-light-light-gray;
    // z-index: 200;
    overflow: hidden;
    &-expanded {
      width: 355px;
    }
  }
}

.popupModal__container.archive-attachment {
  .popupModal__root {
    padding: 16px;
    width: 396px;
    border-radius: 6px;
    min-height: 103px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
      background-color: $color-light-light-gray;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $color-light-light-gray;
      border-radius: 10px;
      background-color: $color-light-light-gray;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px $color-oldSilver;
      background-color: $color-oldSilver;
    }
    .popupModal__heading {
      .icon-close {
        top: 20px;
        right: 20px;
        font-size: 13px;
      }
      h2 {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: $color-primary-dark;
        text-align: justify;
        margin: 0px 0px 25px;
      }
    }
    .popupModal__body {
      min-height: auto;
      .flex-btn {
        float: right;
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        margin: 20px 0 0;

        .cancel-btn {
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 24px;
          color: $color-oldSilver;
          border: 1px solid $color-oldSilver;
          background: $color-white;
          height: 28px;
          width: 71px;
        }

        .delete-btn {
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 24px;
          color: $color-white;
          background: $color-light-red;
          height: 28px;
          min-width: 71px;
          position: relative;
          padding: 0px 16px;
          border: none;
        }

        .delete-btn.loading::after {
          content: "";
          position: absolute;
          border-radius: 100%;
          right: 6px;
          top: 50%;
          width: 0px;
          height: 0px;
          margin-top: -2px;
          border: 2px solid rgba(255, 255, 255, 0.5);
          border-left-color: #fff;
          border-top-color: #fff;
          animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
        }

        @keyframes spin {
          to {
            transform: rotate(359deg);
          }
        }

        @keyframes grow {
          to {
            width: 14px;
            height: 14px;
            margin-top: -8px;
            right: 13px;
          }
        }
      }
    }
  }
}

/* Switch Container */
.switch-container {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 16px; /* Adjusted proportionally */
}

/* Hide the default checkbox */
.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider (track) */
.switch-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-light-light-gray;
  transition: 0.4s;
  border-radius: 16px; /* Half of the height */
}

/* The switch circle (thumb) */
.switch-label:before {
  position: absolute;
  content: "";
  height: 12px; /* Slightly smaller than the track height */
  width: 12px; /* Circle width matches the height */
  left: 2px; /* Adjusted to fit the smaller track */
  bottom: 2px;
  background-color: $color-primary-dark;
  transition: 0.4s;
  border-radius: 50%;
}

/* Checked state */
.switch-input:checked + .switch-label {
  background-color: $color-light-light-gray;
}

/* Move the circle when checked */
.switch-input:checked + .switch-label:before {
  transform: translateX(12px); /* Adjusted to fit within the 28px width */
}

.collapseCard.moduledataShow {
  background: none;
  margin: 0 0 10px 0;
  .collapseCard__heading {
    position: relative;
    background: none;
    color: $color-darkLiver;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon-chevron-thin-down {
      // position: absolute;
      // right: 16px;
      // top: 10px;
      border: 1px solid $color-darkLiver;
      border-radius: 50px;
      padding: 2px;
    }
  }
  .collapseCard__heading.active.showChildren {
    background: $color-primary-light-3;
    padding: 8px 8px 0px;
    .icon-chevron-thin-down {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
    }
  }
  .collapseCard__body.show {
    background: $color-primary-light-3;
    .list-info {
      position: relative;
      padding: 0 0 0 4px;
      &::before {
        content: " ";
        position: absolute;
        left: 4px;
        bottom: 2px;
        height: 100%;
        width: 1px;
        background: $color-darkLiver;
      }
      .list-info-child {
        position: relative;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        text-align: left;
        margin: 0 0 10px 0;
        padding: 0 0 0 15px;
        & .middote {
          font-size: 16px;
        }
        &::before {
          content: " ";
          position: absolute;
          left: 0;
          bottom: 2px;
          height: 1px;
          width: 12px;
          background: $color-darkLiver;
        }
      }
    }
  }
}

/* this default in collapseCard dont remove or edit*/
.collapseCard__heading {
  cursor: default;
}
.collapseCard__heading.showChildren {
  cursor: pointer;
}

.ticket_info_container_updatedDetails .collapseCard.moduleDetails {
  overflow: visible !important;
  .collapseCard__body .moduleDetailsContent .collapseCard {
    overflow: visible !important;
  }
}
